<template>
  <base-template>
    <div class="top-area">
      <div class="title">{{ dataForm.newsTitle }}</div>
      <div class="time">{{ dataForm.publichTime }}</div>
    </div>
    <div class="content">{{ dataForm.newsContent }}</div>
  </base-template>
</template>

<script>
import { setNewsRead } from '@/api/axios/index'
export default {
  data() {
    return {
      dataForm: {
        newsTitle: '',
        publichTime: '',
        newsContent: ''
      }
    }
  },
  created() {
    this.getNesDetail()
  },
  methods: {
    async setNewsRead(params) {
      await setNewsRead(params)
    },

    // 设置详情
    getNesDetail() {
      const data = this.$store.state.settings.newsDetail
      this.dataForm.newsTitle = data.fishbeliIla
      this.dataForm.publichTime = data.UkutaifisaNokupa
      this.dataForm.newsContent = data.mya_fuUmya
      if (!data.srgeervi) this.setNewsRead({ AgitaID: data.id })
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.is-content){
  background: #fff;
}
.title{
  font-size: 32px;
  font-weight: 500;
  color: #000000;
  line-height: 48px;
  padding-bottom: 32px;
}
.time{
  font-size: 24px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #B8B8D2;
  line-height: 28px;
}
.content{
  font-size: 28px;
  color: #666666;
  line-height: 44px;
}

.top-area{
  padding-top: 64px;
  position: relative;
  margin-bottom: 32px;
}
</style>
